import React from "react";
import {Button, Dimmer, Divider, Header, Image, Loader} from "semantic-ui-react";
import withTrans from "../../i18n/withTrans";
import {formatQuery} from "../../controllers/utilities/string-utils";
import {setAdItem, ImgVideoSmall, environmentMode} from "../lib/ad-manage-api-lib";
import result_success2 from "../../images/result/result_success2.jpg";
import result_success2_cards from "../../images/result/result_success2_cards.png";
import result_success2_cards2 from "../../images/result/result_success2_cards.png";
import result_fail1 from "../../images/result/result_fail1.png";
import result_fail2 from "../../images/result/result_fail2.png";
import Background1 from "../../images/btn-003.png";
import j4back from "../../images/j4_background.png";
import j4down from "../../images/j4_download.png";
import jggapp from "../../images/jgg_app.png";
import continue2 from "../../images/main/continue2_2.png";
import result_successbox from "../../images/main/result_successbox.png";
import result_failbox from "../../images/main/result_failbox.png";
import box1 from "../../images/result/box1.png"
import hint from "../../images/result/hint.png";
import NavigationBar from "../forms/navigation-bar";

export default  withTrans((props) => {
    let {ready, status, gold,balance, message, adimage, adurl,adeventId, onContinue,oldsuccess, oldmessage, t, productId} = props;
    let oldmessage1 = oldmessage;
    if (!oldmessage1) oldmessage1 = "";
    const {transactionId, accessToken, gameId, attributionId} = formatQuery(props.q);

    if ((status === "Completed") && ((productId === "67dab3be-2022-4e70-0221-vipacc63c001")  || (productId === "67dab3be-2022-4e70-0221-vipacc63c002") || (productId === "67dab3be-2022-4e70-0221-vipacc63c003") || (productId === "67dab3be-2021-4e70-1014-895acc63c001") || (productId === "67dab3be-2021-4e70-1014-895acc63c002") || (productId === "67dab3be-2021-4e70-1014-895acc63c003") || (productId === "67dab3be-2021-4e70-1014-895acc63c004") || (productId === "67dab3be-2022-4e70-0314-895acc63c004") || (productId === "67dab3be-2021-4e70-1026-895acc63c001") || (productId === "67dab3be-2021-4e70-1026-895acc63c002") || (productId === "67dab3be-2021-4e70-1026-895acc63c003") || (productId === "67dab3be-2022-4e70-0314-895acc63c003"))){
        message = "玩家可在每天00:15 (GMT+8)後, 前往訊息頁檢查到帳記錄";
    }

    // console.log(accessToken);

    // let queryString = props.q;
    // if(queryString.startsWith("?"))
    //     queryString = queryString.substring(1);
    // const list = queryString.split("&");
    // let newGameId = "";
    // let newaccessToken = "";
    // if (list.length > 0) {
    //     let pairs = list[0].split("=");
    //     newGameId = pairs[1];
    //     pairs = list[3].split("=");
    //     newaccessToken = pairs[1];
    // }
    // console.log("ccccccc");
    // console.log(adimage);
    let adItem1 = { "adimg":"", "adlink":"","eventId":"" };
    adItem1.adimg = adimage;
    adItem1.adlink = adurl;
    adItem1.eventId = adeventId;

    return (
        <div>
            <NavigationBar mode={"LANDSCAPE"} platform={"h5"} showLogo={1} showTitle={0} onBack={onContinue}/>            
            <div style={{width:"50%",float:"left",
            marginTop: "60px",
        }}>                
                <div style={{
                                height:`${(status==="Completed")?"23em":"14em"}`,
                                padding:"10px",
                                backgroundImage: `url(${box1})` , backgroundSize:'100% 100%', margin: '3%', borderRadius: '0.5rem'}}>
                    <Dimmer page active={!ready}>
                        <Loader/>
                    </Dimmer>
                    {ready &&
                    <div style={{fontSize: '1.2rem', padding: '10px'}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'begin',
                            marginBottom: '10px'
                        }}>
    <span style={{
        fontWeight: '700',
        width: '30%',
        textAlign: 'right'
    }}>Transaction:</span>
                            <span style={{
                                width: '60%',
                                textAlign: 'left'
                            }}>{transactionId}</span>
                        </div>

                        {
                            (status === "Completed") && (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'begin',
                                        marginBottom: '4px',
                                        padding: "4px",
                                        background: "transparent",
            backgroundImage: `url(${result_successbox})` , backgroundSize:'100% 100%',
                                        color: "#FFF"
                                    }}>
    <span style={{
        fontWeight: '700',
        width: '45%',
        textAlign: 'right'
    }}>Status:</span>
                                        <span style={{
                                            width: '60%',
                                            textAlign: 'left',
                                            paddingLeft: '4px'
                                        }}>{resolveTransactionStatus(status)}</span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'begin',
                                        marginBottom: '4px',
                                        padding: "4px",
                                        background: "transparent",
                                        backgroundImage: `url(${result_successbox})` , backgroundSize:'100% 100%',                        
                                        color: "#FFF"
                                    }}>
            <span style={{
                fontWeight: '700',
                width: '45%',
                textAlign: 'right',
    
            }}>已充值OP幣:</span>
                                        <span style={{
                                            width: '60%',
                                            textAlign: 'left',
                                            paddingLeft: '4px'
                                        }}>{gold}
                                        {(ready) && ((productId === "67dab3be-2022-4e70-0221-vipacc63c001")  || (productId === "67dab3be-2022-4e70-0221-vipacc63c002") || (productId === "67dab3be-2022-4e70-0221-vipacc63c003") || (productId === "67dab3be-2021-4e70-1014-895acc63c001") || (productId === "67dab3be-2021-4e70-1014-895acc63c003") || (productId === "67dab3be-2021-4e70-1026-895acc63c001") || (productId === "67dab3be-2021-4e70-1026-895acc63c002")) && (
        <> (將會在每天00:15 GMT+8自動到帳)</>
                )}
                {(ready) && ((productId === "67dab3be-2021-4e70-1014-895acc63c002") || (productId === "67dab3be-2021-4e70-1014-895acc63c004") || (productId === "67dab3be-2022-4e70-0314-895acc63c004") || (productId === "67dab3be-2021-4e70-1026-895acc63c003") || (productId === "67dab3be-2022-4e70-0314-895acc63c003")) && (
        <> (將會在每天00:15 GMT+8自動到帳)</>
                )}
                                        </span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'begin',
                                        marginBottom: '4px',
                                        padding: "4px",
                                        background: "transparent",
                                        backgroundImage: `url(${result_successbox})` , backgroundSize:'100% 100%',                        
                                        color: "#FFF"
                                    }}>
            <span style={{
                fontWeight: '700',
                width: '45%',
                textAlign: 'right',
            }}>現有OP幣:</span>
                                        <span style={{
                                            width: '60%',
                                            textAlign: 'left',
                                            paddingLeft: '4px'
                                        }}>{balance}</span>
                                    </div>
                                </>)
                        }

                        {
                            (status !== "Completed") && (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'begin',
                                        marginBottom: '10px',
                                        padding: "4px",
                                        background: "transparent",
                                        backgroundImage: `url(${result_failbox})` , backgroundSize:'100% 100%',                        
                                        color: "#FFF"
                                    }}>
    <span style={{
        fontWeight: '700',
        width: '30%',
        textAlign: 'right'
    }}>Status:</span>
                                        <span style={{
                                            width: '60%',
                                            textAlign: 'left'
                                        }}>{resolveTransactionStatus(status)}</span>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'begin',
                                        marginBottom: '10px'
                                    }}>
    <span style={{
        fontWeight: '700',
        width: '30%',
        textAlign: 'right'
    }}>Message:</span>
                                        <span style={{
                                            width: '60%',
                                            textAlign: 'left'
                                        }}>{decodeURI(message).split("|")[0]}</span>
                                    </div>

                                </>
                            )
                        }
                        
                    </div>
                    }
                    {/* <Header style={{marginTop: '2px',paddingBottom: '5px'}}>如有問題，請截圖本頁面聯絡客服</Header> */}
                    <Image style={{
                    marginBottom: "4vh",
                    marginTop: "2vh",
                    height:"1.2em"
                }} centered src={hint}/>
                    {/*<Divider/>*/}
                </div>

                {(status !== "Completed") &&
                (<div style={{marginTop: "1em"}}><Button fluid onClick={onContinue}
                                style={{
                                    height: "4em",
                                    background: "transparent",
                                    backgroundImage: `url(${continue2})` , backgroundSize:'100% 100%'
                                }}>
                            &nbsp;
                        </Button></div>)}
            </div>
            <div style={{width:"40%",float:"right",marginTop:"3%",marginRight: "5%"}}>
                <div style={{backgroundColor: 'transparent', margin: '3%', marginTop:"3rem",  borderRadius: '0.5rem'}}>
                {(productId !== "67dab3be-2022-4e70-0221-vipacc63c001")  && (productId !== "67dab3be-2022-4e70-0221-vipacc63c002") && (productId !== "67dab3be-2022-4e70-0221-vipacc63c003") && (productId !== "67dab3be-2021-4e70-1014-895acc63c001") && (productId !== "67dab3be-2021-4e70-1014-895acc63c002") && (productId !== "67dab3be-2021-4e70-1014-895acc63c003") && (productId !== "67dab3be-2021-4e70-1014-895acc63c004") && (productId !== "67dab3be-2022-4e70-0314-895acc63c004") && (productId !== "67dab3be-2021-4e70-1026-895acc63c001") && (productId !== "67dab3be-2021-4e70-1026-895acc63c002") && (productId !== "67dab3be-2021-4e70-1026-895acc63c003") && (productId !== "67dab3be-2022-4e70-0314-895acc63c003") && (productId !== "67dab3be-2021-4e70-0812-895acc63c022") && (productId !== "67dab3be-2021-4e70-0812-895acc63c002") && (
        <><Image style={{
                    marginBottom: "2vh",
                    marginTop: "2vh",
                    borderRadius: '0.5rem',
                    height: `${(status==="Completed")?"18em":"19em"}`
                }} centered src={returnImage(status, message)}/></>
                )}
        {(ready) && ((productId === "67dab3be-2021-4e70-1014-895acc63c001") || (productId === "67dab3be-2021-4e70-1014-895acc63c002") || (productId === "67dab3be-2021-4e70-1014-895acc63c003") || (productId === "67dab3be-2021-4e70-1014-895acc63c004") || (productId === "67dab3be-2022-4e70-0314-895acc63c004") || (productId === "67dab3be-2021-4e70-1026-895acc63c001") || (productId === "67dab3be-2021-4e70-1026-895acc63c002") || (productId === "67dab3be-2021-4e70-1026-895acc63c003") || (productId === "67dab3be-2022-4e70-0314-895acc63c003")) && (
        <><Image style={{
                    marginBottom: "2vh",
                    marginTop: "2vh",
                    borderRadius: '0.5rem',
                    height:`${(status==="Completed")?"18em":"19em"}`
                }} centered src={result_success2_cards}/></>
                )}
                {(ready) && ((productId === "67dab3be-2022-4e70-0221-vipacc63c001")  || (productId === "67dab3be-2022-4e70-0221-vipacc63c002") || (productId === "67dab3be-2022-4e70-0221-vipacc63c003")) && (
        <><Image style={{
                    marginBottom: "2vh",
                    marginTop: "2vh",
                    borderRadius: '0.5rem',
                    height:`${(status==="Completed")?"18em":"19em"}`
                }} centered src={result_success2_cards2}/></>
                )}
                 {(ready) && ((productId === "67dab3be-2021-4e70-0812-895acc63c022") || (productId === "67dab3be-2021-4e70-0812-895acc63c002")) && (
        <><Image style={{
                    marginBottom: "4vh",
                    marginTop: "2vh",
                    borderRadius: '0.5rem',
                    height:`${(status==="Completed")?"18em":"19em"}`
                }} centered src={j4back}/>
                
                <Image 
                onClick={() => {
                    const newurl = "https://m.jparadise.xyz/account/login?r=download";
                    window.location.href = newurl;
                }}
                style={{
                     position:"relative",
                     width:"88%",
                     marginTop: "calc(-100% / 2.6)",
                }} centered src={j4down}/>
                <Image 
                onClick={() => {
                    let isIOS = "0";
                    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  
                        isIOS = "1";
                    }
                    if (isIOS === "0"){
                        const newurl = "https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download";
                        window.location.href = newurl;
                    }else{
                        const newurl = "https://ss.xpmikami.com/game/xr/";
                        window.location.href = newurl;
                    }
                
            }}
                style={{
                     position:"relative",
                     width:"88%",
                     marginTop: "calc(8%)",
                }} centered src={jggapp}/>
                </>
                )}
                </div>
                {(status === "Completed") &&
                (<Button fluid onClick={onContinue}
                                style={{
                                    height: "4em",
                                    background: "transparent",
                                    backgroundImage: `url(${continue2})` , backgroundSize:'100% 100%'
                                }}>
                            &nbsp;
                        </Button>)}
            </div>
            
            {/*<a href="#"*/}
            {/*   onClick={() => {*/}
            {/*       showAd("P1", adItem1.adlink, adItem1.eventId,  gameId, attributionId, accessToken, transactionId, oldmessage1, oldsuccess);*/}
            {/*   }}*/}
            {/*><ImgVideoSmall style={{*/}

            {/*}} centered src={adItem1.adimg}/></a>*/}
    </div>
);
});

async function showAd(sType, Url, eventId, gameId, attributionId, accessToken, transactionId, message, success){
    // window.location.href = Url;
    // window.open(Url + "&r1=download", 'newwindow'+ sType);
    // window.location.href = Url + "&r1=download";
    // 暂时去掉ios两板功能
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        Url = "";
    }
    if (Url !== ""){
        const url = Url + "&rr1=download";
        //const uuid = Buffer.from(url).toString('base64');
        const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
        // console.log(uuid);
        // gameId=06ea3f8f-04fa-4b21-800d-3c2220f11eb2&attributionId=9&accessToken=aafb7f27-da9d-4198-a301-0dd8f73761d2&transactionId=e2815246-7db3-4a41-95e4-e14bb85ee2cf
        let newurl =  `/gotoad-return-result/?gameId=${gameId}&attributionId=${attributionId}&accessToken=${accessToken}&transactionId=${transactionId}&uuid=${uuid}&message=${message}&success=${success}&r1=download`;
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            newurl =  `/gotoadios/?gameId=${gameId}&attributionId=${attributionId}&accessToken=${accessToken}&transactionId=${transactionId}&uuid=${uuid}&message=${message}&success=${success}&r1=download`;
        }
        // console.log(newurl);
        window.location.href = newurl;
        // setAdItem(accessToken,eventId,sType);
    }
}

function returnImage(status, message){
    if (message === "transaction not found") return result_fail2;
    switch(status) {
        case "Created":
            return result_fail1;
        case "Confirmed":
            return result_fail2;
        case "Completed":
            return result_success2;
        default:
            return result_fail1;
    }
}
function resolveTransactionStatus(status) {
    switch(status) {
        case "Created":
            return "增值不成功";
        case "Confirmed":
            return "支付失敗";
        case "Completed":
            return "增值成功";
        default:
            return "增值失敗";
    }
}