import React, {Component} from "react";
import {connect} from "react-redux";
import withLocation from "../wrappers/with-location";
import {formatQuery} from "../../controllers/utilities/string-utils";
import KaisakuApi from "../../controllers/kaisaku-api";
import {navigate} from "gatsby";
import View from "./payment-result-view2";
import {getAdPItem} from "../lib/ad-manage-api-lib";
import {rejectIfAnyNull} from "../../controllers/utilities/env-checks";
import cookie from "react-cookies";

class PaymentResult extends Component {
    constructor(props, context) {
        super(props, context);
        //console.log("222");
        //console.log(this.props.q);
        this.state = {
            ready: false,
            adItem1:  { "adimg":"", "adlink":"", "eventId":""}
        };
        // console.log("123");
        // console.log(this.props);
        // console.log(this.state);
    }

    async componentDidMount() {
        saveToCookie(this.props.location.search);
        let {transactionId, success, message} = formatQuery(this.props.location.search);
        // console.log("11");
        // console.log(this.props.location.search);
        // console.log(this.props);
        // console.log(this.state);
        let newaccessToken = "";
        if ((this.props.session !== null) && (this.props.session.accessToken !== null)){
            // console.log("111");
            newaccessToken = this.props.session.accessToken;
        }else{
            let queryString = this.props.location.search;
            if(queryString.startsWith("?"))
                queryString = queryString.substring(1);
            const list = queryString.split("accessToken=");
            // console.log("222");
            if (list.length > 1) {
                // console.log("123321");
                // console.log(list);
                if (list.length >= 1) {
                    let pairs = list[1].split("&");
                    newaccessToken = pairs[0];
                }
            }
        }
        let isIOS = "0";
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // 暂时去掉ios两板功能
            isIOS = "1";
        }
        // const res = await getAdPItem(newaccessToken, isIOS);
        // // console.log("ooo");
        // // console.log(res.adP1Image);
        // this.setState({
        //     adItem1:  { "adimg": res.adP1Image, "adlink": res.adP1Url ,"eventId": res.eventId}
        // });
        if ((!success) || (success.toLowerCase() === "true")) {
            let data;
            // if (newaccessToken){
            //     data = await KaisakuApi.getCreditTransactionDetails(transactionId, newaccessToken);
            // }else{
            //     data = await KaisakuApi.getCreditTransactionStatus(transactionId);
            // }
            data = await KaisakuApi.getCreditTransactionDetails(transactionId);
            console.log(data.transaction);
            if(data.transaction) {
                if (!message) message = "";
                this.setState({
                    ready: true,
                    status: data.transaction.status,
                    transactionId,
                    gold: data.transaction.gold,
                    balance: data.transaction.balance,
                    price: data.transaction.price,
                    currency: data.transaction.currency,
                    createdAt: data.transaction.createdAt,
                    updatedAt: data.transaction.updatedAt,
                    showTransactionId: data.transaction.showTransactionId,
                    userId: data.transaction.userId,
                    productId: data.transaction.productId,
                    message,
                    oldsuccess:success,
                    oldmessage:message
                });
            } else {
                this.setState({
                    ready: true,
                    status: "Unknown",
                    transactionId,
                    gold: 0,
                    balance: 0,
                    price: "",
                    currency: "",
                    createdAt: "",
                    updatedAt: "",
                    showTransactionId: "",
                    userId: "",
                    message: data.message,
                    oldsuccess:success,
                    oldmessage:message
                });
            }
        } else {
            this.setState({
                ready: true,
                status: "Unknown",
                transactionId,
                message
            });
        }
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.q);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/confirm-purchase");
        }
    }

    onGameSupport() {
        const {transactionId} = formatQuery(this.props.q);
        // navigate(`/game-support/?transactionId=${transactionId}`);
        navigate(`/payment-support-addticket/?transactionId=${transactionId}`);
    }

    onPaymentSupport() {
        const {transactionId} = formatQuery(this.props.q);
        navigate(`/payment-support/?transactionId=${transactionId}`);
    }

    render() {
        return (
            <View ready={this.state.ready} status={this.state.status} message={this.state.message} q={this.props.q}
                  oldsuccess={this.state.oldsuccess} oldmessage={this.state.oldmessage}
                  adimage={this.state.adItem1.adimg} adurl={this.state.adItem1.adlink} adeventId={this.state.adItem1.eventId}
                  onContinue={this.onContinue.bind(this)}
                  onGameSupport={this.onGameSupport.bind(this)}
                  onPaymentSupport={this.onPaymentSupport.bind(this)}
                  gold={this.state.gold}
                  balance={this.state.balance}
                  transactionId={this.state.transactionId}
                  price={this.state.price}
                  currency={this.state.currency}
                  createdAt={this.state.createdAt}
                  updatedAt={this.state.updatedAt}
                  showTransactionId={this.state.showTransactionId}
                  userId={this.state.userId}
                  productId={this.state.productId}/>
        );
    }
}

export default connect(state => {
    return {session: state.session};
})(withLocation(PaymentResult));

function saveToCookie(q){
    let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(q);
    if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)) {

        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        cookie.save("_gameId", newGameId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_accessToken", newAccessToken, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_transactionId", pendingTransactionId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_attributionId", newAttributionId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
    }
}